<template>
  <div id="page-user-view" class="px-4">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Payout Methods')}}</h2>
      <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
        {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
      </vs-alert>
      <span v-else class="font-medium text-primary cursor-pointer">
        <vs-button
        color="primary"
        class="px-5 py-3 btn active-btn rounded-lg"
        @click="newPayoutMethod()"
        >
          <div class="flex items-center">
            <span class="text-base">{{$t('Add Payout Method')}}</span>
          </div>
        </vs-button>
      </span>
    </div>
    <shipblu-table
      :sst="true"
      pagination
      :max-items="maximumItems"
      :data="payouts"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Account name')}}</shipblu-th>
        <shipblu-th>{{$t('Wallet Number')}}</shipblu-th>
        <shipblu-th>{{$t('Bank Name')}}</shipblu-th>
        <shipblu-th>{{$t('Account Number')}}</shipblu-th>
        <shipblu-th>{{$t('Default')}}</shipblu-th>
        <shipblu-th v-if="merchant.access_payout_methods" class="order-1 flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].walletNumber">
            {{ data[indextr].bank ? data[indextr].bank.creditor_name : '' }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].walletNumber">
            {{ data[indextr].wallet ? data[indextr].wallet.number : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].bankName">
            {{ data[indextr].bank ? data[indextr].bank.name : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].accountNumber">
            {{ data[indextr].bank ? data[indextr].bank.account_number : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].is_active">
            <span v-if="data[indextr].is_active">
              <feather-icon
                icon="CheckCircleIcon"
                svgClasses="h-6 w-7"
                class="text-green"
              />
            </span>
          </shipblu-td>

          <shipblu-td v-if="merchant.access_payout_methods" class="order-2" style="vertical-align: middle;">
            <vx-tooltip v-if="merchantStatus === 'active' && new Date().getHours() > 6 && new Date().getHours() < 18" :text="$t('You can\'t change your payout method at the time period of 6 to 18')">
              <div class="md:text-center text-right">
                <vs-dropdown
                  vs-trigger-click
                  @click="selected = []"
                  class="dd-actions cursor-pointer"
                >
                  <span class="material-symbols-outlined text-2xl">more_horiz</span>
                  <vs-dropdown-menu style="width: 190px">
                    <vs-dropdown-item :disabled="data[indextr].is_active" @click="updatePayout(data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="CheckCircleIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Set Default')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </vx-tooltip>
            <div v-else class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item @click="updatePayout(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="CheckCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Set Default')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-payout :pinModal="pinModal" @pinModal="pinModal = $event" :payoutModal="payoutModal" @payoutModal="payoutModal = $event" :payout="payout" @loadPayouts="loadPayouts"></add-payout>
    <OtpVerification :errorMessage="errorMessage" :pinModal="pinModal" @pinModal="pinModal = $event" :pinValue="pinValue" @addPayout="addPayout" @pinValue="pinValue = $event"/>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import AddPayout from './components/AddPayout.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import OtpVerification from './components/OtpVerification.vue'
import i18nData from '../../i18n/i18nData.js'
import common from '../../assets/utils/common.js'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      errorMessage: '',
      successPin: '',
      merchant:'',
      pinValue: '',
      pinModal: false,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      payouts: [],
      payoutModal: false,
      payout: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page && Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      tableLoader: false,
      merchantStatus: ''
    }
  },
  watch : {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPayouts()
    },
    successPin (val) {
      if (val === true) {
        this.pinModal = false
        this.errorMessage = ''
        this.payoutModal = false
      }
    },
    pinModal (val) {
      if (val === true) {
        this.errorMessage = ''
      }
    }
  },
  methods: {
    addPayout () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.errorMessage = ''
          const payout = {
            otp: String(this.pinValue),
            type: this.payout.payoutType.value,
            number: this.payout.number,
            account_number: this.payout.account_number,
            creditor_name: this.payout.creditor_name,
            name: this.payout.bank.name,
            swift_code: this.payout.bank.swift
          }
          sendRequest(false, false, this, 'api/v1/accounting/payout/methods/', 'post', payout, true,
            () => {
              this.successPin = true
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Payment'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.loadPayouts()
              this.pinValue = ''
            }, (error) => {
              this.successPin = false
              this.errorMessage = error.response.data.message
              this.$vs.loading.close()
            }
          )
          this.pinValue = ''
        }
      })
    },
    loadPayouts () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/accounting/payout/methods/${query}`, 'get', null, true,
        (response) => {
          this.payouts = response.data.results
          this.totalRows = response.data.count
          if (this.totalRows === 1 && !response.data.results[0].is_active) {
            this.updatePayout(response.data.results[0])
          }
          this.tableLoader = false
        }
      )
    },
    newPayoutMethod () {
      this.payout = {
        bank: '',
        payoutType: ''
      }
      this.payoutModal = true
    },
    updatePayout (data) {
      sendRequest(false, false, this, `api/v1/accounting/payout/methods/${data.id}/`, 'patch', {is_active: true}, true,
        () => {
          this.loadPayouts()
        }
      )
    }
  },
  components: {
    AddPayout,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    OtpVerification,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantStatus = user.status
      this.merchant = results.userMerchantData
      this.loadPayouts()
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
table.shipblu-table td:first-child {
  border-left: 0px solid;
}
</style>
