<template>
  <div :class="pinModal ? 'show-modal' : 'hidden1'" class="modal-window fixed" :style="pinModal ? '' : 'z-index: -1;'">
    <div class="rounded-lg absolute p-6 popup-w">
      <feather-icon icon="XIcon" svgClasses="w-4 h-4" @click="closePinModal" class="cursor-pointer flex justify-end"/>
      <div class="w-full sm:grid justify-items-center block text-center">
        <img src="../../../assets/images/pages/OTP-Verification.png" alt="user-img" width="130px" height="130px"/>
        <p class="mt-2 font-medium text-primary text-xl">{{$t('OTP Verification')}}</p>
        <p class="mt-2 font-medium text-lg">
          {{$t('Enter OTP Code sent to')}} <span class="text-primary"> {{merchant ? merchant.phone_number : userMerchant.phone_number}}</span>
        </p>
      </div>
      <div style="direction: ltr" class="grid grid-cols-4 p-6 justify-items-center">
        <div v-for="(pin, index) in pin" :key="index">
          <input
            v-validate="'required|numeric|length:1'"
            :class="errorMessage == '' ? 'input-pin' : 'input-pin-denger'"
            type="text"
            id="pinId"
            v-model="pin.value"
            ref="pin"
            name="pin"
            @focus="showValue($event.target.value,index)"
            @keyup.delete="back($event.target.value, index)"
            @input="moveToNextField($event.target.value, index)">
        </div>
      </div>
      <p class="text-danger text-sm text-center underline underline-offset-4" v-show="errors.has('pin')">{{ errors.first('pin') }}</p>
      <p class="text-danger text-sm text-center underline underline-offset-4" v-if="errorMessage !== ''">{{$t('The code is incorrect, enter the code again')}}</p>
      <div class="w-full sm:grid justify-items-center block text-center pt-3">
        <div class="p-2 background-style">
          <span class="text-primary"><feather-icon icon="ClockIcon" svgClasses="h-3 w-3" class="mr-1" />{{timerCount}} {{$t('seconds')}}</span> 
        </div>
        <p class="mt-2 font-medium">
          {{$t('Didn’t receive code?')}} <button @click="resendFunction()" class="btn py-1 px-2" :class="resend ? 'active text-white' : 'disabled'">{{ $t('Resend code') }}</button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import common from '../../../assets/utils/common'

export default {
  props: ['pinModal', 'pinValue', 'errorMessage', 'merchant'],
  data () {
    return {
      userMerchant: '',
      pin: [
        { id: '1', value: '' },
        { id: '2', value: '' },
        { id: '3', value: '' },
        { id: '4', value: '' }
      ],
      timerCount: 0,
      resend: false,
      focusedValue: '',
      focusedIndex: ''
    }
  },
  watch: {
    pinModal (val) {
      if (val === true) {
        this.pin.map(item => { item.value = '' })
        const input = document.getElementById('pinId') 
        input.focus()
      }
      if (this.timerCount === 0) {
        this.timerCount = 60
      }
    },
    timerCount: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        } else {
          this.resend = true
        }
      }
    },
    errorMessage () {
      if (this.errorMessage !== '') {
        this.pinValue = ''
        this.pin.forEach(item => {
          item.value = ''
        })
      }
    }
  },
  methods: {
    showValue (value, index) {
      this.focusedValue = value
      this.focusedIndex = index
    },
    back (value, index) {
      const prevndex = index - 1
      if (this.focusedValue !== null && this.focusedIndex) {
        this.pin[index].value = '' 
        this.$refs.pin[prevndex].focus()
      }
    },
    resendFunction () {
      this.merchant && this.merchant.first_name ? this.sendSignUpOtp() : this.sendOTP()
    },
    moveToNextField (value, index) {
      const nextIndex = index + 1
      if (nextIndex < this.pin.length && value.length > 0) {
        this.$refs.pin[nextIndex].focus()
      }
      if (this.pin[0].value.length === 1 && this.pin[3].value.length === 1 && this.pin[1].value.length === 1 && this.pin[2].value.length === 1) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.pin.forEach(item => {
              this.pinValue += `${item.value}`
            })
            this.$emit('pinValue', this.pinValue)
            this.$emit('addPayout')
            this.$emit('addMerchant')
          }
        })
      }
    },
    sendSignUpOtp () {
      if (this.resend === true) {
        sendRequest(false, false, this, 'api/v1/self-signup/otp/', 'post', {phone_number: this.merchant.phone_number, email: this.merchant.email}, false,
          (response) => {
            this.$vs.notify({
              color: 'success',
              title: i18nData[this.$i18n.locale]['Success'],
              text: response.data.message,
              position: 'top-center'
            })
          }
        )
        this.resend = false
        this.timerCount = 60
      }
    },
    sendOTP () {
      if (this.resend === true) {
        sendRequest(false, false, this, 'api/v1/accounting/payout-methods/otp/', 'post', {}, true,
          (response) => {
            this.$vs.notify({
              color: 'success',
              title: i18nData[this.$i18n.locale]['Success'],
              text: response.data.message,
              position: 'top-center'
            })
          }
        )
        this.resend = false
        this.timerCount = 60
      }
    },
    closePinModal () {
      this.errorMessage = ''
      this.$emit('resend')
      this.$emit('loadOrder')
      this.$emit('pinModal', false)
    }
  },
  created () {
    if (this.$route.name !== 'page-sign-up') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      merchantData.then(results => {
        this.userMerchant = results.userMerchantData
      })
    }
      
  }
}
</script>

<style scoped lang="scss">
.background-style{
  background: rgba(28, 91, 254, 0.05);
  border-radius: 14.5px;
}
.input-pin{
  width: 38px;
  height: 49px;
  left: 610px;
  top: 442px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.17);
  box-shadow: 0px 0px 8px rgba(28, 91, 254, 0.17);
  border-radius: 6px;
  text-align: center;
  font-size: 25px;
  &:focus {
    outline: none;
    border: 1px solid #1C5BFE;
    box-shadow: 0px 0px 8px rgba(28, 91, 254, 0.17);
  }
}
.input-pin-denger{
  width: 38px;
  height: 49px;
  left: 610px;
  top: 442px;
  background: rgba(210, 28, 28, 0.09);
  border: 1px solid #D21C1C;
  box-shadow: 0px 0px 8px rgba(210, 28, 28, 0.09);
  border-radius: 6px;
  text-align: center;
  font-size: 25px;
}
.disabled {
  background: #F1F3F6;
  color: #6C84A3;
  pointer-events: none;
}
.popup-w{
  max-width: 480px;
  min-width: 270px;
}
.underline-offset-4{
  text-underline-offset: 4px;
}
.modal-window {
  background: rgba(10, 50, 102, 0.4);
  backdrop-filter: blur(6px);
  opacity: 0;
  transition: all 0.3s;
  & > div {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.8);
    min-width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.show-modal {
  visibility: visible;
  opacity: 1 !important;
  position: fixed;
  inset: 0;
  z-index: 99999;
  background: rgba(10, 50, 102, 0.24);
  backdrop-filter: blur(2px);
  opacity: 0;
  transition: all 0.3s ease;
}
</style>